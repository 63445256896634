import React, { useContext, useState, useEffect } from 'react';
import question_mark from 'media/question-mark@2x.png';
import swal from 'sweetalert';
import Textfield from './Textfield';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Tab1, StyledSelect } from 'styles/Styled';
import { Ring } from 'react-awesome-spinners';

import {
  Visor,
  VisorPrecio,
  EtiquetaPrecioFiat,
  EtiquetaNombre,
  CryptoIcon,
  CalculatorContainer,
  ContainerMinMax,
  MinMaxElement,
  CalculatorTabs,
  IconClickable,
  Convers,
  Content,
} from './styles';

import { AuthContext } from 'Auth';
import agentebtcapi from 'agentebtcapi.js';
import Catcher from './Catcher';

import ObjectCalculator from 'components/object-calculator/ObjectCalculator';

const initialConvertedLimits = {
  btc: {
    sell: [0, 0],
    buy: [0, 0],
  },
  usd: {
    sell: [0, 0],
    buy: [0, 0],
  },
  pen: {
    sell: [0, 0],
    buy: [0, 0],
  },
  hnl: {
    sell: [0, 0],
    buy: [0, 0],
  },
};

// Function to convert string to uppercase
const UpperToLowerCase = string => {
  return string.toLowerCase();
};

// Functino to convert first character to uppercase and the rest to lowercase
const firstToUpperCase = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const Calculator = ({ selected }) => {
  const [calculatorObj, setCalculatorObj] = useState(undefined);
  const { currentToken } = useContext(AuthContext);
  const [menuFiat, setMenuFiat] = useState(false);
  const [isTron, setIsTron] = useState(false);
  const [cryptos, setCryptos] = useState([]);
  const [cryptosBalance, setCryptosBalance] = useState({
    BTC: '0.0000',
    RBTC: '0.0000',
    USDC: '0.0000',
    USDT: '0.0000',
    ALIC: '0.00',
    ADA: '0.0000',
    DOGE: '0.0000',
    MATIC: '0.0000',
    ETH: '0.0000',
    SHIB: '0.0000',
    SOL: '0.0000',
  });

  const [calculatorViewer, setCalculatorViewer] = useState({
    types: ['IN', 'OUT'],
    ammounts: ['', ''],
    currencies: ['BTC', 'USD'],
    transactionType: 'BUY', // SELL/BUY
    rates: {
      BTCUSDBuy: 0.0, // This is shown on landing
      BTCUSDSell: 0.0,
      BTCPENBuy: 0.0,
      USDPENBuy: 0.0,
      USDPENSell: 0.0,
    },
  });

  const [cryptoCurr, setCryptoCurr] = useState('BTC');
  const [cryptoOrder, setCryptoOrder] = useState(0);
  const [inputA, setInputA] = useState('');
  const [inputB, setInputB] = useState('');
  const [limits, setLimits] = useState(initialConvertedLimits);
  const [currentFiatCurrency, setCurrentFiatCurrency] = useState('USD'); // FIAT current selector
  const [transactionState, setTransactionState] = useState('BUY');
  //* ******************************//
  //* ******************************//
  //* ******************************//

  // Coupons
  const [user, setDataUser] = useState({});
  const [cuponValue, setCuponValue] = useState(0);

  // Balances
  const [balancePEN, setBalancePEN] = useState(0);
  const [balanceUSD, setBalanceUSD] = useState(0);
  const [balanceHNL, setBalanceHNL] = useState(0);

  /* ======= COMPRA - VENTA ======= */

  const handleBtnCompra = () => {
    setCryptoCurr('BTC');
    setTransactionState('BUY');
    setCurrentFiatCurrency('USD');
    setInputB(0);
    setInputA(0);
    calculatorObj.setBUY();
    const btnVenta = document.querySelector('.btn-venta');
    const btnCompra = document.querySelector('.btn-compra');
    btnVenta.classList.remove('active-tab-new');
    btnVenta.style.cursor = 'pointer';
    btnCompra.classList.add('active-tab-new');
    btnCompra.style.cursor = 'default';
    calculatorObj.setUSDOTHER(cryptoCurr, 'USD');
  };

  const handleBtnVenta = () => {
    setInputB(0);
    setInputA(0);
    setCryptoCurr('BTC');
    setCurrentFiatCurrency('USD');
    calculatorObj.setSELL();
    setTransactionState('SELL');
    const btnVenta = document.querySelector('.btn-venta');
    const btnCompra = document.querySelector('.btn-compra');
    btnCompra.classList.remove('active-tab-new');
    btnCompra.style.cursor = 'pointer';
    btnVenta.classList.add('active-tab-new');
    btnVenta.style.cursor = 'default';
    calculatorObj.setUSDOTHER(cryptoCurr, 'USD');
  };

  const calculatorObserver = async data => {
    setCalculatorViewer(data);
    const inputTypeA = data.types[0];
    console.loh("data.converter_limits:", data.converted_limits)
    setLimits(data.converted_limits);
  };

  /****************** AFC FIAT Refactor *********/
  // Set the value to devide
  let fiatOperationFormat = `${UpperToLowerCase(currentFiatCurrency)}${firstToUpperCase(transactionState)}`;
  const valueToDevide = calculatorViewer?.cryptoPrices?.[cryptoCurr][fiatOperationFormat];


  const handleInputABuy = e => {
    console.log("Input A Buy")
    // ADD REGEX
    let val = e.target.value;
    const re = /^(?:(?:|[0-9]*)(?:.[0-9]{0,8})?|.[0-9]{0,8})$/;
    if (re.test(val)) {
      if (
        (currentFiatCurrency === 'USD' && val * valueToDevide <= 100000) ||
        (currentFiatCurrency === 'PEN' && val * valueToDevide <= 374000) ||
        (currentFiatCurrency === 'HNL' && val * valueToDevide <= 1000000) // AFC
      ) {
        setInputB(+parseFloat(val * valueToDevide).toFixed(8));
        setInputA(val);
        calculatorObj.setAmmountA(val);
      }
    }
  };

  const handleInputASell = e => {
    console.log("Input A Sell")
    // ADD REGEX
    let val = e.target.value;
    const re = /^(?:(?:|[0-9]*)(?:.[0-9]{0,8})?|.[0-9]{0,8})$/;
    if (re.test(val)) {
      if (
        (currentFiatCurrency === 'USD' && val * valueToDevide <= 100000) ||
        (currentFiatCurrency === 'PEN' && val * valueToDevide <= 374000) || 
        (currentFiatCurrency === 'HNL' && val * valueToDevide <= 1000000) // AFC
      ) {
        setInputA(val);
        setInputB(+parseFloat(val * valueToDevide).toFixed(8));
        calculatorObj.setAmmountA(val);
      }
    }
  };

  const handleInputBBuy = e => {
    console.log("Input B Buy")
    // ADD REGEX
    const val = e.target.value;
    const re = /^(?:(?:|[0-9]*)(?:.[0-9]{0,2})?|.[0-9]{0,2})$/;
    if (re.test(val)) {
      if (
        (currentFiatCurrency === 'USD' && val <= 100000) ||
        (currentFiatCurrency === 'PEN' && val <= 374000) || 
        (currentFiatCurrency === 'HNL' && val <= 100000) // AFC
      ) {
        setInputB(val);
        setInputA(+parseFloat(val / valueToDevide).toFixed(8));
        calculatorObj.setAmmountB(val);
      }
    }
  };
  const handleInputBSell = e => {
    console.log("Input B Sell")
    // ADD REGEX
    const val = e.target.value;
    const re = /^(?:(?:|[0-9]*)(?:.[0-9]{0,2})?|.[0-9]{0,2})$/;
    if (re.test(val)) {
      if (
        (currentFiatCurrency === 'USD' && val <= 100000) ||
        (currentFiatCurrency === 'HNL' && val <= 100000) ||
        (currentFiatCurrency === 'PEN' && val <= 374000)
      ) {
        setInputB(val);
        setInputA(+parseFloat(val / valueToDevide).toFixed(8));
        calculatorObj.setAmmountB(val);
      }
    }
  };

  const showInfoAboutPrice = () => {
    swal({
      text:
        'Los precios son referenciales y son actualizados cada 10 segundos.',
      button: false,
      timer: 3000,
    });
  };

  // Feature: Cupones

  const getUser = async () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setDataUser(responseJson);
      })
      .catch(error => {
        console.log('Ha habido un error en nuestro sistema');
      });
  };

  const getCryptos = async () => {
    const token = localStorage.getItem('token-agente-btc');
    const endpoint = '/public/cryptos';
    const url = `${agentebtcapi}${endpoint}`;
    const data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setCryptos(responseJson);
        /*if(responseJson.hasRegisterCoupon){
              getCoupon(responseJson.registerCouponId);
            }*/
      })
      .catch(error => {
        console.log('Ha habido un error en nuestro sistema');
      });
  };

  // Balances


  // Wallets of all user actives
  const getWallet = async () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/wallet';
    let url = `${agentebtcapi}${endpoint}`;
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        responseJson.forEach(function(item) {
          if (item.currency == 'BTC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { BTC: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'PEN') {
            if (item.balance) {
              setBalancePEN(item.balance.toFixed(2));
            }
          }

          if (item.currency == 'HNL') { // AFC
            if (item.balance) {
              setBalanceHNL(item.balance.toFixed(2));
            }
          }

          if (item.currency == 'USD') {
            if (item.balance) {
              setBalanceUSD(item.balance.toFixed(2));
            }
          }

          if (item.currency == 'USDT') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { USDT: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'RBTC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { RBTC: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'USDC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { USDC: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }
          
          
          if (item.currency == 'ADA') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { ADA: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'DOGE') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { DOGE: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'ALIC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { ALIC: item.balance.toFixed(4) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'ETH') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { ETH: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'MATIC') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { MATIC: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'SHIB') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { SHIB: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }

          if (item.currency == 'SOL') {
            if (item.balance) {
              let updatedValue = {};
              updatedValue = { SOL: item.balance.toFixed(8) };
              setCryptosBalance(cryptosBalance => ({
                ...cryptosBalance,
                ...updatedValue,
              }));
            }
          }
        });
      })
      .catch(error => {});
  };

  useEffect(() => {
    getUser();
    setCuponValue(cuponValue);
    const calculatorObjTmp = new ObjectCalculator(calculatorObserver);
    setCalculatorObj(calculatorObjTmp);
    calculatorObjTmp.getPrice();
    const interval = setInterval(() => {
      calculatorObjTmp.getPrice();
      setCalculatorObj(calculatorObjTmp);
    }, 10000);

    getWallet();
    getCryptos();
    return () => clearInterval(interval);
  }, [cuponValue]);


  // Original Select FIAT
  const SelectFIAT = option => {
    //console.log("Select fiat...", option.value)

    // TEMPORAL AFC
    // if( option.value === 'HNL' ){
    //   option.value = 'USD';
    // }

    const fiatOperationFormatSelect = `${UpperToLowerCase(option.value)}${firstToUpperCase(transactionState)}`;
    let valueToSet = 0;
    
    // TEMPORAL AFC
    if( option.value !== 'HNL' ){
      valueToSet = calculatorViewer?.cryptoPrices?.[cryptoCurr][fiatOperationFormatSelect];
    }else{
      //console.log("TC:", calculatorViewer?.cryptoPrices?.[cryptoCurr][fiatOperationFormatSelect])
      valueToSet = 100;
      valueToSet = calculatorViewer?.cryptoPrices?.[cryptoCurr][fiatOperationFormatSelect];
    }

    setCurrentFiatCurrency(option.value);
    calculatorObj.setUSDOTHER(cryptoCurr, option.value); // PENDING CALCULATOR

    transactionState === 'BUY'
      ? setInputA((inputB / valueToSet).toFixed(8))
      : setInputB((inputA * valueToSet).toFixed(2));

      // console.log("Option value: ", option.value);
      // console.log("Select fiat... 2", inputA, inputB, valueToSet)
      // console.log("Input A: ", inputA)
      // console.log("Input B: ", inputB)
      // console.log("Value to set: ", valueToSet)      
  };

  const SelectCryptoCurrency = option => {
    //console.log("1Select crypto currency...")
  
    const fiatOperationFormatSelect = `${UpperToLowerCase(currentFiatCurrency)}${firstToUpperCase(transactionState)}`;
    const valueToSet = calculatorViewer?.cryptoPrices?.[cryptos[option.value]?.symbol][fiatOperationFormatSelect];

    setCryptoOrder(option.value);
    setCryptoCurr(cryptos[option.value].symbol);
    setInputB((inputA * valueToSet).toFixed(2));
    calculatorObj.setUSDOTHER(
      cryptos[option.value]?.symbol,
      currentFiatCurrency //!== "HNL" ? currentFiatCurrency : "PEN" // TEMPORAL AFC
    );
  };

  const toggleMenuFiat = option => {
    setMenuFiat(!menuFiat);
  };

  const SelectCrypto = option => {
    //console.log("2Select crypto...", option.label, currentFiatCurrency )
    setInputB(0);
    setInputA(0);

    calculatorObj.setUSDOTHER(option.label, currentFiatCurrency);
    
    // let newOptionValue = {
    //   value: currentFiatCurrency,
    // }
    // SelectFIAT(newOptionValue);
  };

  if (selected == 'sale') {
    handleBtnVenta();
  }

  // Checks if values are within limits
  const checkCorrectOfConvert = transaction => {
    if (
      currentFiatCurrency === 'PEN' &&
      inputB < (transaction ? limits.pen.sell[0] : limits.pen.buy[0])
    ) {
      return (
        <div
          style={{
            marginTop: -20,
            textAlign: 'left',
            marginLeft: 5,
            color: 'red',
            fontSize: 12,
          }}
        >

          {console.log("Limits minimoes", limits.pen.buy)}
          Mínimo es {transaction ? limits.pen.sell[0] : limits.pen.buy[0]}
        </div>
      );
    } else if (
      currentFiatCurrency === 'USD' &&
      inputB < (transaction ? limits.usd.sell[0] : limits.usd.buy[0])
    ) {
      return (
        <div
          style={{
            marginTop: -20,
            textAlign: 'left',
            marginLeft: 5,
            color: 'red',
            fontSize: 12,
          }}
        >
          Mínimo es {transaction ? limits.usd.sell[0] : limits.usd.buy[0]}
        </div>
      );
    } else if (
      currentFiatCurrency === 'HNL' &&
      inputB < (transaction ? limits.hnl.sell[0] : limits.hnl.buy[0])
    ) {
      return (
        <div
          style={{
            marginTop: -20,
            textAlign: 'left',
            marginLeft: 5,
            color: 'red',
            fontSize: 12,
          }}
        >
          Mínimo es {transaction ? limits.hnl.sell[0] : limits.hnl.buy[0] }
        </div>
      );
    }
  };




  const testLog=(test) => {
    console.log('test', test);
  }

  return (
    <CalculatorContainer
      style={
        calculatorViewer?.cryptoPrices?.[cryptoCurr]?.usdBuy
          ? {
              opacity: 1,
            }
          : {
              opacity: 0.5,
            }
      }
    >
      {calculatorViewer?.cryptoPrices?.[cryptoCurr]?.usdBuy ? (
        <></>
      ) : (
        <div
          style={{
            zIndex: 2,
            width: 40,
            height: 40,
            alignSelf: 'center',
            position: 'absolute',
            top: '35%',
          }}
        >
          <Ring size={45} />
        </div>
      )}
      <Convers>
        <CalculatorTabs>
          <Tab1
            type="button"
            onClick={handleBtnCompra}
            disabled={
              calculatorViewer?.cryptoPrices?.[cryptoCurr]?.usdBuy
                ? false
                : true
            }
            className="btn-compra active-tab-new"
            style={{
              display: 'block',
              borderRightWidth: 1,
              borderRightColor: 'lightgrey',
            }}
          >
            Comprar
          </Tab1>
          <Tab1
            type="button"
            disabled={
              calculatorViewer?.cryptoPrices?.[cryptoCurr]?.usdBuy
                ? false
                : true
            }
            onClick={handleBtnVenta}
            className="btn-venta"
            style={{ display: 'block' }}
          >
            Vender
          </Tab1>
        </CalculatorTabs>
        <Content>

          {/* Buy Section */}
          {transactionState === 'BUY' ? (
            <div>
              <Visor>
                <EtiquetaPrecioFiat
                  onClick={
                    calculatorViewer?.cryptoPrices?.[cryptoCurr]?.usdBuy
                      ? toggleMenuFiat
                      : undefined
                  }
                >
                  <EtiquetaNombre>{currentFiatCurrency}</EtiquetaNombre>
                  <div
                    style={{
                      paddingLeft: 20,
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                      }}
                    >
                      <CryptoIcon
                        src={
                          // AFC
                          currentFiatCurrency == 'USD'
                            ? 'https://icon-library.com/images/dollar-png-icon/dollar-png-icon-28.jpg'
                            : currentFiatCurrency == 'PEN'
                            ? 'https://cdn3.iconfinder.com/data/icons/major-currencies-24px-glyphs/48/sol_peruvian_currency_PEN-512.png'
                            : 'https://i.imgur.com/5cRwRUe.png'
                        }
                      />
                    </div>
                    <i
                      style={{
                        display: 'inline',
                        paddingLeft: 40,
                        color: 'black',
                        fontSize: 18,
                      }}
                    >
                      {currentFiatCurrency == 'USD' 
                        ? 'Dólares' 
                        : currentFiatCurrency == 'PEN'
                        ? 'Soles'
                        : 'Lempiras' // AFC
                      }
                    </i>
                    <i
                      style={{
                        display: 'inline',
                        paddingLeft: 10,
                        fontSize: 18,
                      }}
                    >
                      {currentFiatCurrency == 'USD' 
                        ? '$' 
                        : currentFiatCurrency == 'PEN'
                        ? '/S'
                        : '/L' // AFC
                      }
                    </i>
                  </div>
                  {menuFiat ? (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      style={{
                        position: 'absolute',
                        right: 16,
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      style={{
                        position: 'absolute',
                        right: 16,
                      }}
                    />
                  )}

                  <StyledSelect
                    value={{
                      value: currentFiatCurrency,
                      label: currentFiatCurrency,
                    }}
                    onChange={SelectFIAT}
                    styles={{
                      control: styles => ({
                        ...styles,
                        display: 'none',
                      }),
                    }}
                    
                    /* Dropdown with fiat currency */
                    menuIsOpen={menuFiat}
                    options={[
                      /* USD */
                      {
                        value: 'USD',
                        label: (
                          <>
                            <EtiquetaNombre>USD</EtiquetaNombre>
                            <div
                              style={{
                                paddingLeft: 20,
                                flexDirection: 'row',
                              }}
                            >
                              <div
                                style={{
                                  position: 'absolute',
                                }}
                              >
                                <CryptoIcon
                                  src={
                                    'https://icon-library.com/images/dollar-png-icon/dollar-png-icon-28.jpg'
                                  }
                                />
                              </div>
                              <i
                                style={{
                                  display: 'inline',
                                  paddingLeft: 40,
                                  color: 'black',
                                  fontSize: 18,
                                }}
                              >
                                Dólares
                              </i>
                              <i
                                style={{
                                  display: 'inline',
                                  paddingLeft: 10,
                                  fontSize: 18,
                                }}
                              >
                                $
                              </i>
                            </div>
                          </>
                        ),
                      },
                      /* PEN */
                      {
                        value: 'PEN',
                        label: (
                          <>
                            <EtiquetaNombre>PEN</EtiquetaNombre>
                            <div
                              style={{
                                paddingLeft: 20,
                                flexDirection: 'row',
                              }}
                            >
                              <div
                                style={{
                                  position: 'absolute',
                                }}
                              >
                                <CryptoIcon
                                  src={
                                    'https://cdn3.iconfinder.com/data/icons/major-currencies-24px-glyphs/48/sol_peruvian_currency_PEN-512.png'
                                  }
                                />
                              </div>
                              <i
                                style={{
                                  display: 'inline',
                                  paddingLeft: 40,
                                  color: 'black',
                                  fontSize: 18,
                                }}
                              >
                                Soles
                              </i>
                              <i
                                style={{
                                  display: 'inline',
                                  paddingLeft: 10,
                                  fontSize: 18,
                                }}
                              >
                                /S
                              </i>
                            </div>
                          </>
                        ),
                      },
                      /* HNL */
                      {
                        value: 'HNL',
                        label: (
                          <>
                            <EtiquetaNombre>HNL</EtiquetaNombre>
                            <div
                              style={{
                                paddingLeft: 20,
                                flexDirection: 'row',
                              }}
                            >
                              <div
                                style={{
                                  position: 'absolute',
                                }}
                              >
                                <CryptoIcon
                                  src={
                                    'https://i.imgur.com/5cRwRUe.png'
                                  }
                                />
                              </div>
                              <i
                                style={{
                                  display: 'inline',
                                  paddingLeft: 40,
                                  color: 'black',
                                  fontSize: 18,
                                }}
                              >
                                Lempiras
                              </i>
                              <i
                                style={{
                                  display: 'inline',
                                  paddingLeft: 10,
                                  fontSize: 18,
                                }}
                              >
                                L.
                              </i>
                            </div>
                          </>
                        ),
                      },
                    ]}
                  />
                </EtiquetaPrecioFiat>
              </Visor>
              <VisorPrecio style={{ justifyContent: 'center' }}>
                <IconClickable
                  src={question_mark}
                  onClick={showInfoAboutPrice}
                ></IconClickable>
                <a style={{ fontSize: 15 }}>
                  <>
                    1 {cryptoCurr} = {valueToDevide}{' '}
                    {/* AFC */}
                    {currentFiatCurrency === 'USD' ? 'USD' : currentFiatCurrency ==='PEN' ? 'PEN' : 'HNL'} 
                  </>
                </a>
              </VisorPrecio>

              <Textfield
                placeholder={currentFiatCurrency}
                value={inputB}
                onChange={handleInputBBuy}
                type={true}
                disabled={
                  calculatorViewer?.cryptoPrices?.[cryptoCurr]?.usdBuy
                    ? false
                    : true
                }
              />
              {checkCorrectOfConvert(false)}

              <Textfield
                disabled={
                  calculatorViewer?.cryptoPrices?.[cryptoCurr]?.usdBuy
                    ? false
                    : true
                }
                placeholder={cryptoCurr}
                selectOnChange={e => {
                  setCryptoCurr(e.value);
                  SelectCrypto(e);
                  const valueToDevides =
                    transactionState === 'BUY'
                      ? currentFiatCurrency === 'PEN'
                        ? calculatorViewer?.cryptoPrices?.[e.value]?.penBuy
                        : calculatorViewer?.cryptoPrices?.[e.value]?.usdBuy
                      : currentFiatCurrency === 'PEN'
                      ? calculatorViewer?.cryptoPrices?.[e.value]?.penSell
                      : calculatorViewer?.cryptoPrices?.[e.value]?.usdSell;
                }}
                value={inputA}
                onChange={handleInputABuy}
                isCrypto
                cryptoArray={cryptos.filter( f => ( f.allowBuy === true ) )}
                vender
                calculatorValue={cryptoCurr}
              />
            </div>
          ) : 
          /* Sell Section */
            (
            <div>
              <Visor>
                <EtiquetaPrecioFiat onClick={toggleMenuFiat}>
                  <EtiquetaNombre>{cryptoCurr}</EtiquetaNombre>
                  <div
                    style={{
                      paddingLeft: 20,
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                      }}
                    >
                      <img
                        style={{
                          width: 40,
                          display: 'inline-block',
                          paddingTop: 8,
                          paddingRight: 15,
                        }}
                        src={cryptos[cryptoOrder].image}
                      />
                    </div>
                    <i
                      style={{
                        display: 'inline',
                        paddingLeft: 40,
                        color: 'black',
                        fontSize: 18,
                      }}
                    >
                      {cryptos[cryptoOrder].name}
                    </i>
                    <i
                      style={{
                        display: 'inline',
                        paddingLeft: 10,
                        fontSize: 18,
                      }}
                    >
                      {cryptos[cryptoOrder].symbol}
                    </i>
                  </div>
                  {/* Fiat currency in sell section */}
                  {menuFiat ? (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      style={{
                        position: 'absolute',
                        right: 16,
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      style={{
                        position: 'absolute',
                        right: 16,
                      }}
                    />
                  )}
                  <StyledSelect
                    value={{
                      value: cryptoOrder,
                      label: cryptoCurr,
                    }}
                    onChange={SelectCryptoCurrency}
                    styles={{
                      control: styles => ({
                        ...styles,
                        display: 'none',
                      }),
                    }}
                    menuIsOpen={menuFiat}
                    options={cryptos.filter(fc => (fc.allowSell)).map(crypto => {

                      return {
                        value: crypto.order - 1,
                        label: (
                          <>
                            <EtiquetaNombre>{crypto.name}</EtiquetaNombre>
                            <div
                              style={{
                                paddingLeft: 20,
                                flexDirection: 'row',
                              }}
                            >
                              <div
                                style={{
                                  position: 'absolute',
                                }}
                              >
                                <img
                                  style={{
                                    width: 40,
                                    display: 'inline-block',
                                    paddingTop: 8,
                                    paddingRight: 15,
                                  }}
                                  src={crypto.image}
                                />
                              </div>
                              <i
                                style={{
                                  display: 'inline',
                                  paddingLeft: 40,
                                  color: 'black',
                                  fontSize: 18,
                                }}
                              >
                                {crypto.name}
                              </i>
                              <i
                                style={{
                                  display: 'inline',
                                  paddingLeft: 10,
                                  fontSize: 18,
                                }}
                              >
                                {crypto.symbol}
                              </i>
                            </div>
                          </>
                        ),
                      };
                    })}
                  />
                </EtiquetaPrecioFiat>
              </Visor>
              <VisorPrecio style={{ justifyContent: 'center' }}>
                <IconClickable
                  src={question_mark}
                  onClick={showInfoAboutPrice}
                ></IconClickable>
                <a style={{ fontSize: 15 }}>
                  <>
                    1 {cryptoCurr} = {valueToDevide}{' '}
                    { currentFiatCurrency === 'USD' ? 'USD' : currentFiatCurrency === 'PEN' ? 'PEN' : 'HNL' }
                  </>
                </a>
              </VisorPrecio>
              <Textfield
                placeholder={cryptoCurr}
                value={inputA}
                onChange={handleInputASell}
                type={true}
              />

              <Textfield
                placeholder={currentFiatCurrency}
                value={inputB}
                vender
                onChange={handleInputBSell}
                calculatorValue={inputA}
                selectOnChange={SelectFIAT}
              />
              {checkCorrectOfConvert(true)} 
            </div>
          )}



          {/* Buy HNL within limits */}
          {currentToken &&
            transactionState === 'BUY' &&
            currentFiatCurrency === 'HNL' &&
            inputB >= limits.hnl.buy[0] &&
            parseFloat(inputB) <= balanceHNL && (
              <Catcher
                title="Comprar"
                calculatorViewer={calculatorViewer}
                ammountCrypto={inputA}
                ammountFiat={inputB}
                cryptoName={cryptoCurr}
                fiatName={currentFiatCurrency}
              />
            )}


              {/* Buy HNL with more steps? */}
          {transactionState === 'BUY' &&
            currentFiatCurrency === 'HNL' &&
            (inputB < limits.hnl.buy[0] ||
              parseFloat(inputB) > balanceHNL ||
              inputB > limits.hnl.buy[1]) && (
              <Catcher
                title="Comprar"
                disabled
                noBalance={parseFloat(inputB) > balanceUSD ? true : false}
                calculatorViewer={calculatorViewer}
                ammountCrypto={inputA}
                ammountFiat={inputB}
                cryptoName={cryptoCurr}
                fiatName={currentFiatCurrency}
              />
            )}

          {/* Buy USD within limits */}
          {currentToken &&
            transactionState === 'BUY' &&
            currentFiatCurrency === 'USD' &&
            inputB >= limits.usd.buy[0] &&
            parseFloat(inputB) <= balanceUSD && (
              <Catcher
                title="Comprar"
                calculatorViewer={calculatorViewer}
                ammountCrypto={inputA}
                ammountFiat={inputB}
                cryptoName={cryptoCurr}
                fiatName={currentFiatCurrency}
              />
            )}
          {/* Buy PEN within limits */}
          {currentToken &&
            transactionState === 'BUY' &&
            currentFiatCurrency === 'PEN' &&
            inputB >= limits.pen.buy[0] &&
            parseFloat(inputB) <= balancePEN && (
              <Catcher
                title="Comprar"
                calculatorViewer={calculatorViewer}
                ammountCrypto={inputA}
                ammountFiat={inputB}
                cryptoName={cryptoCurr}
                fiatName={currentFiatCurrency}
              />
            )}
          {/* Buy USD with more steps? */}
          {transactionState === 'BUY' &&
            currentFiatCurrency === 'USD' &&
            (inputB < limits.usd.buy[0] ||
              parseFloat(inputB) > balanceUSD ||
              inputB > limits.usd.buy[1]) && (
              <Catcher
                title="Comprar"
                disabled
                noBalance={parseFloat(inputB) > balanceUSD ? true : false}
                calculatorViewer={calculatorViewer}
                ammountCrypto={inputA}
                ammountFiat={inputB}
                cryptoName={cryptoCurr}
                fiatName={currentFiatCurrency}
              />
            )}
          {/* Buy PEN with more steps? */}
          {transactionState === 'BUY' &&
            currentFiatCurrency === 'PEN' &&
            (inputB < limits.pen.buy[0] ||
              inputB > limits.pen.buy[1] ||
              parseFloat(inputB) > balancePEN) && (
              <Catcher
                title="Comprar"
                disabled
                noBalance={parseFloat(inputB) > balancePEN ? true : false}
                calculatorViewer={calculatorViewer}
                ammountCrypto={inputA}
                ammountFiat={inputB}
                cryptoName={cryptoCurr}
                fiatName={currentFiatCurrency}
              />
            )}

          {currentToken &&
            transactionState === 'SELL' &&
            currentFiatCurrency === 'USD' &&
            inputB >= limits.usd.sell[0] &&
            inputB <= limits.usd.sell[1] &&
            parseFloat(inputA) <= cryptosBalance[cryptoCurr] && (
              <Catcher
                title="Vender"
                calculatorViewer={calculatorViewer}
                ammountCrypto={inputA}
                ammountFiat={inputB}
                cryptoName={cryptoCurr}
                fiatName={currentFiatCurrency}
              />
            )}


          {/* { testLog(limits.usd) }
          { testLog(cryptosBalance[cryptoCurr]) }
          { testLog(cryptoCurr) } */}

          {transactionState === 'SELL' &&
            currentFiatCurrency === 'USD' &&
            (inputB < limits.usd.sell[0] ||
              inputB > limits.usd.sell[1] ||
              parseFloat(inputA) > cryptosBalance[cryptoCurr]) && (
              <Catcher
                title="Vender"
                disabled
                noBalance={inputA > cryptosBalance[cryptoCurr] ? true : false}
                calculatorViewer={calculatorViewer}
                ammountCrypto={inputA}
                ammountFiat={inputB}
                cryptoName={cryptoCurr}
                fiatName={currentFiatCurrency}
              />
            )}


            {currentToken &&
            transactionState === 'SELL' &&
            currentFiatCurrency === 'PEN' &&
            inputB >= limits.pen.sell[0] &&
            inputB <= limits.pen.sell[1] &&
            parseFloat(inputA) <= cryptosBalance[cryptoCurr] && (
              <Catcher
                title="Vender"
                calculatorViewer={calculatorViewer}
                ammountCrypto={inputA}
                ammountFiat={inputB}
                cryptoName={cryptoCurr}
                fiatName={currentFiatCurrency}
              />
            )}


          {transactionState === 'SELL' &&
            currentFiatCurrency === 'PEN' &&
            (inputB < limits.pen.sell[0] ||
              inputB > limits.pen.sell[1] ||
              parseFloat(inputA) > cryptosBalance[cryptoCurr]) && (
              <Catcher
                title="Vender"
                disabled
                noBalance={inputA > cryptosBalance[cryptoCurr] ? true : false}
                calculatorViewer={calculatorViewer}
                ammountCrypto={inputA}
                ammountFiat={inputB}
                cryptoName={cryptoCurr}
                fiatName={currentFiatCurrency}
              />
            )}


            {currentToken &&
            transactionState === 'SELL' &&
            currentFiatCurrency === 'HNL' &&
            inputB >= limits.hnl.sell[0] &&
            inputB <= limits.hnl.sell[1] &&
            parseFloat(inputA) <= cryptosBalance[cryptoCurr] && (
              <Catcher
                title="Vender"
                calculatorViewer={calculatorViewer}
                ammountCrypto={inputA}
                ammountFiat={inputB}
                cryptoName={cryptoCurr}
                fiatName={currentFiatCurrency}
              />
            )}


            {transactionState === 'SELL' &&
            currentFiatCurrency === 'HNL' &&
            (inputB < limits.hnl.sell[0] ||
              inputB > limits.hnl.sell[1] ||
              parseFloat(inputA) > cryptosBalance[cryptoCurr]) && (
              <Catcher
                title="Vender"
                disabled
                noBalance={inputA > cryptosBalance[cryptoCurr] ? true : false}
                calculatorViewer={calculatorViewer}
                ammountCrypto={inputA}
                ammountFiat={inputB}
                cryptoName={cryptoCurr}
                fiatName={currentFiatCurrency}
              />
            )}


          <ContainerMinMax>
            <MinMaxElement>
              {`Min::
          ${ limits[currentFiatCurrency.toLowerCase()] ?
            limits[currentFiatCurrency.toLowerCase()][
              transactionState.toLowerCase()
            ][0] : 0
          }
          ${currentFiatCurrency}`}
            </MinMaxElement>
            <MinMaxElement>
              {`Max:
          ${ limits[currentFiatCurrency.toLowerCase()] ?
            limits[currentFiatCurrency.toLowerCase()][
              transactionState.toLowerCase()
            ][1] : 0
          } ${currentFiatCurrency}`}
            </MinMaxElement>
          </ContainerMinMax>
        </Content>
      </Convers>
    </CalculatorContainer>
  );
};

export default Calculator;
