
// TODO Por qué los límites de las monedas están en esta función y no se jalan del backend?
export const FIAT_ASSETS = [
    { 
        symbol: 'PEN', 
        name: 'Soles', 
        image: 'https://i.imgur.com/H6Ylpff.png', 
        icon: '/S', 
        type: 'FIAT',
        limitL1:  [ 25   , 374000   ],
        limitL2:  [ 25   , 474000   ], 
    },
    { 
        symbol: 'USD',
        name: 'Dólares', 
        image: 'https://i.imgur.com/7M0pIrp.png', 
        icon: '$', 
        type:'FIAT',
        limitL1:  [ 7   , 100000    ],
        limitL2:  [ 7   , 100000    ], 
    },
    { 
        symbol: 'HNL', 
        name: 'Lempiras', 
        image: 'https://i.imgur.com/cK9E867.png', 
        icon: 'L.', 
        type:'FIAT',
        limitL1:  [ 175   , 374000   ],
        limitL2:  [ 175   , 484000   ], 
    },
]

// Validate convertion request
export const validateAndFormatConvertionRequest = (obj) => {
    try{

        // Validate transactionType is 'BUY' or 'SELL'
        if( obj.transactionType !== 'BUY' && obj.transactionType !== 'SELL' ){
            throw new Error('Tipo de conversión inválido.');
        }

        // Validate selectedAssetA and selectedAssetB are set
        if( !obj.selectedAssetA || !obj.selectedAssetB ){
            throw new Error('Por favor seleccione un activo.');
        }

        // Validate amountA and AmountB are set
        if( !obj.amountA || !obj.amountB ){
            throw new Error('Por favor ingrese un monto.');
        }

        // Remove commas from the string and convert to float number 
        let _amountA = obj.amountA.replace(/,/g, '');
        let _amountB = obj.amountB.replace(/,/g, '');

        // Validate amountA and amountB are numbers
        if( isNaN(_amountA) || isNaN(_amountB) ){
            throw new Error('Por favor ingrese un monto válido.');
        }

        // Validate amountA and amountB are positive numbers
        if( _amountA <= 0 || _amountB <= 0 ){
            throw new Error('Por favor ingrese un monto positivo.');
        }

        // BUY - Validate amountA are within the limits
        if( obj.transactionType === 'BUY' && (_amountA < obj.minAmount || _amountA > obj.maxAmount) ){
            // Get min and max amount in money format 
            let minAmount = new Intl.NumberFormat('en-US').format(obj.minAmount);
            let maxAmount = new Intl.NumberFormat('en-US').format(obj.maxAmount);
            throw new Error(`El monto debe ser entre ${minAmount} y ${maxAmount} ${obj.selectedAssetA}`);
        }

        // SELL - Validate amountB are within the limits
        if( obj.transactionType === 'SELL' && (_amountB < obj.minAmount || _amountB > obj.maxAmount) ){
            // Get min and max amount in money format 
            let minAmount = new Intl.NumberFormat('en-US').format(obj.minAmount);
            let maxAmount = new Intl.NumberFormat('en-US').format(obj.maxAmount);
            throw new Error(`El monto debe ser entre ${minAmount} y ${maxAmount} ${obj.selectedAssetB}`);
        }

        // Get convertion request object
        let convertionRequest = getConvertionRequestObject(obj);
        if( convertionRequest instanceof Error ){
            throw new Error('Error al obtener la solicitud de conversión.');
        }

        // Return convertion request object
        return convertionRequest;

    }catch(error){
        return error;
    }
}

// Get Convertion Request Object
export const getConvertionRequestObject = (obj) => {
    try{

        // Generate new object
        let newObject = {};

        // If transactionType is 'BUY'
        if( obj.transactionType === 'BUY' ){
            newObject = {
                from: obj.selectedAssetA,
                to: obj.selectedAssetB,
                amount: parseFloat(obj.amountA.replace(/,/g, '')),
            }
        }

        // If transactionType is 'SELL'
        if( obj.transactionType === 'SELL' ){
            newObject = {
                from: obj.selectedAssetB,
                to: obj.selectedAssetA,
                amount: parseFloat(obj.amountB.replace(/,/g, '')),
            }
        }

        // Update newObject with transactionType and psst
        newObject.transactionType = obj.transactionType;
        newObject.psst = obj.psst;
        
        return newObject;
    }catch(error){
        return error;
    }
}

// Validate and transform input numbers to money format
export const validateInputNumber = (input) => {

    if( input === '' ) return '';
    if( input === '.' ) return '0.';

    try{
        let _input = input;

        // Remove commas from the string
        _input = _input.replace(/,/g, '');

        // Remove all non numeric characters except dots
        _input = _input.replace(/[^0-9.]/g, '');

        // Last character is a dot
        const lastIsDot = _input.slice(-1) === '.';

        // Verify if string has more than one dot
        let _dots = _input.match(/\./g);
        if( _dots !== null && _dots.length > 1 ){
            return null;
        }else if( _dots === null ){
            _dots = [];
        }else{
            _dots = _dots.map(dot => dot);
        }
        const lastIsCero = _input.slice(-1) === '0';

        // Test if the string is a positive number
        let _number = parseFloat(_input);
        if( (isNaN(_number) || _number < 0) && !lastIsDot && !( lastIsCero && _dots.length === 1 ) ){
            return null;
        }

        // Separate the string in integer and decimal parts
        let _integer = '';
        let _decimal = '';
        if( _dots.length === 1 ){
            _integer = _input.split('.')[0];
            _decimal = _input.split('.')[1];

            // Generate money format for the integer part
            _integer = new Intl.NumberFormat('en-US').format(parseInt(_integer));
            return _integer + '.' + _decimal;

        }else{
            _input = new Intl.NumberFormat('en-US').format(parseInt(_input));
            return _input;
        }

    }catch(error){
        return null;
    }
}

// Generate delay for 3 seconds to simulate loading time
export const delay = ms => new Promise(res => setTimeout(res, ms));